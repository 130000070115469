<template>
    <div class="contents">
        <div id="top">
            <img :src="app.picture">
            <input type="text" v-model="app.name">
        </div>
        <br><br>
        <span class="label">PICTURE</span>
        <picture-upload ref="uploadPicture" :profilePicture="app.picture"
                        :uploadUrl="'/api/v2/projects/apps/oauth2/'+id+'/picture'"/>
        <br>
        <span class="label">NAME</span>
        <input type="text" class="input" style="max-width: 400px" v-model="app.name" placeholder="Name">
        <br>
        <span class="label">ID (client_id)</span>
        <input type="text" class="input" style="max-width: 400px" v-model="app.id" readonly placeholder="Identifier">
        <br>
        <span class="label">SECRET (client_secret)</span>
        <input :type="showSecret ? 'text' : 'password'" class="input" style="max-width: 400px" v-model="app.secret"
               readonly placeholder="Secret">
        <a class="button" style="cursor: pointer" @click="showSecret = false" v-if="showSecret">Hide key</a>
        <a class="button" style="cursor: pointer" @click="showSecret = true" v-else>Show key</a>
        <a class="button delete" style="margin-left: 10px; cursor: pointer" @click="regenerateKey"> Regenerate key</a>
        <br><br>
        <span class="label">DEFAULT-REDIRECT-URL (redirect_uri)</span>
        <input type="text" class="input" style="max-width: 400px" v-model="app.default_redirect_url"
               placeholder="Identifier">
        <br>
        <p>Created API-Codes: {{ app.code_count }}</p>
        <br>

        <Modal mtitle="Create Oauth2 URL" ref="urlCreatorModal">
            <span class="label">Redirect-URL (Empty for default)</span>
            <input type="text" v-model="urlCreator.redirectUrl" @change="createUrl()" class="input" placeholder="URL">
            <div v-for="scope of availableScopes" :key="scope">
                <div v-if="!urlCreator.scopes[scope.split(':')[0]] || scope.split(':')[0] == scope">
                    <label :for="'scope-'+scope.replaceAll('.','').replaceAll(':','')"
                           style="margin-right: 10px">{{ scope }}</label>
                    <input @change="createUrl()" v-model="urlCreator.scopes[scope]"
                           :id="'scope-'+scope.replaceAll('.','').replaceAll(':','')" type="checkbox">
                </div>
            </div>
            <br>
            <span class="label">Custom scopes (Seperated by a comma)</span>
            <input type="text" v-model="urlCreator.customScopes" @change="createUrl()" class="input"
                   placeholder="Custom scopes">
            <br>
            <span class="label">Output:</span>
            <input type="text" class="input" readonly v-model="urlCreator.output" placeholder="OAuth2 URL">
        </Modal>

        <Modal ref="trustedURLsModal" mtitle="Trusted URL Modal">
            <p style="opacity: 0.5; width: 90%; padding: 4px 0px;">
                If you use response_type=token, you'll need trusted urls.
            </p>
            <div v-for="(url, i) of app.urls" :key="i">
                <input class="input" type="text" style="width: calc(100% - 43px); display: inline-block"
                       v-model="app.urls[i]">
                <i @click="app.urls = app.urls.filter((_, i2) => i2 != i)" class="uil uil-multiply"
                   style="font-size: 23px; cursor: pointer; vertical-align: middle; margin-left: 10px"/>
            </div>
            <a class="button" @click="app.urls.push('')">Add</a>
        </Modal>

        <a class="button" @click="$refs.urlCreatorModal.open()">URL-Creator</a>
        <a class="button" @click="$refs.trustedURLsModal.open()" style="margin-left: 10px;">Trusted URLs</a>
        <br><br>
        <a class="button delete" @click="deleteApp">Delete App</a>
        <a class="button" style="float: right" @click="save">Save</a>
    </div>
</template>

<script>
import helper from '../../../helper'
import Modal from "../../../components/Modal";
import PictureUpload from '../../../components/PictureUpload.vue';

export default {
    data: () => ({
        id: 0,
        color: "#434343",
        app: {},
        showSecret: false,
        availableScopes: [
            "user", "user:read", "user:edit",
            "user.name", "user.name:read",
            "user.mail", "user.mail:read",
            "user.birthday", "user.birthday:read", "user.birthday:edit",
            "user.description", "user.description:read", "user.description:edit",
            "user.favoritecolor", "user.favoritecolor:read", "user.favoritecolor:edit",
            "user.profilepicture", "user.profilepicture:read", "user.profilepicture:edit",
        ],
        urlCreator: {
            output: "",
            redirectUrl: "",
            scopes: {},
            customScopes: ""
        }
    }),
    components: {
        Modal,
        PictureUpload
    },
    mounted() {
        this.id = this.$route.params.id
        this.load()
        this.$refs.uploadPicture.$on("uploaded", () => {
            this.app.picture = ""
            this.load()
        })
    },
    methods: {
        load() {
            this.apiClient.get("/api/v2/projects/apps/oauth2/" + this.id)
                .then(res => res.json())
                .then(res => {
                    if (res.success)
                        this.app = res
                })
        },
        regenerateKey() {
            this.apiClient.get("/api/v2/projects/apps/oauth2/" + this.id + "/regenerate_secret")
                .then(res => res.json())
                .then(res => {
                    if (res.success) {
                        helper.showSnackBar("Done", '#56ff5e')
                        this.load()
                    }
                })
        },
        deleteApp() {
            this.apiClient.delete("/api/v2/projects/apps/oauth2/" + this.id)
                .then(res => res.json())
                .then(res => {
                    if (res.success) {
                        helper.showSnackBar("Done", '#56ff5e')
                        this.$router.push("/developers/projects")
                    }
                })
        },
        save() {
            this.apiClient.put("/api/v2/projects/apps/oauth2/" + this.id, {
                name: this.app.name,
                redirect_url: this.app.default_redirect_url,
                urls: this.app.urls
            })
                .then(res => res.json())
                .then(res => {
                    if (res.success)
                        helper.showSnackBar("Saved")
                })
        },
        createUrl() {
            this.urlCreator.output = "https://accounts.interaapps.de/auth/oauth2?client_id=" + this.app.id
            if (this.urlCreator.redirectUrl.trim() != '') {
                this.urlCreator.output += "&redirect_uri=" + encodeURIComponent(this.urlCreator.redirectUrl.trim())
            }
            console.log("YE");
            this.urlCreator.output += "&scope="
            console.log(this.urlCreator.scopes);
            let scopesUrl = ""
            for (const scope in this.urlCreator.scopes) {
                if (this.urlCreator.scopes[scope]) {
                    scopesUrl += scope + " "
                }
            }
            for (const scope of this.urlCreator.customScopes.trim().split(',')) {
                scopesUrl += scope.trim() + " "
            }

            if (scopesUrl.trim() != "")
                this.urlCreator.output += encodeURIComponent(scopesUrl.trim())
        }
    }
}
</script>

<style lang="scss" scoped>
#top {
    margin-top: 30px;

    img {
        display: inline-block;
        vertical-align: middle;
        width: 50px;
        height: 50px;
        padding: 4px;
        background: #00000011;
        border-radius: 6px;
    }

    input {
        color: #545454;
        display: inline-block;
        vertical-align: middle;
        margin-left: 20px;
        border: none;
        font-size: 32px;
        font-weight: 600;
        max-width: calc(100% - 100px);
    }
}

h2 {
    color: #878787;
    font-size: 27px;
}
</style>